import React from "react";

interface Props {
  className?: string;
}

const Block = (props: Props) => {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1109 2.88867H4.33312C3.95003 2.88867 3.58263 3.04085 3.31174 3.31174C3.04085 3.58263 2.88867 3.95003 2.88867 4.33312V10.1109C2.88867 10.494 3.04085 10.8614 3.31174 11.1323C3.58263 11.4032 3.95003 11.5553 4.33312 11.5553H10.1109C10.494 11.5553 10.8614 11.4032 11.1323 11.1323C11.4032 10.8614 11.5553 10.494 11.5553 10.1109V4.33312C11.5553 3.95003 11.4032 3.58263 11.1323 3.31174C10.8614 3.04085 10.494 2.88867 10.1109 2.88867ZM4.33312 10.1109V4.33312H10.1109V10.1109H4.33312Z"
        fill="white"
      />
      <path
        d="M21.6675 2.88867H15.8898C15.5067 2.88867 15.1393 3.04085 14.8684 3.31174C14.5975 3.58263 14.4453 3.95003 14.4453 4.33312V10.1109C14.4453 10.494 14.5975 10.8614 14.8684 11.1323C15.1393 11.4032 15.5067 11.5553 15.8898 11.5553H21.6675C22.0506 11.5553 22.418 11.4032 22.6889 11.1323C22.9598 10.8614 23.112 10.494 23.112 10.1109V4.33312C23.112 3.95003 22.9598 3.58263 22.6889 3.31174C22.418 3.04085 22.0506 2.88867 21.6675 2.88867ZM15.8898 10.1109V4.33312H21.6675V10.1109H15.8898Z"
        fill="white"
      />
      <path
        d="M10.1109 14.4443H4.33312C3.95003 14.4443 3.58263 14.5965 3.31174 14.8674C3.04085 15.1383 2.88867 15.5057 2.88867 15.8888V21.6666C2.88867 22.0496 3.04085 22.417 3.31174 22.6879C3.58263 22.9588 3.95003 23.111 4.33312 23.111H10.1109C10.494 23.111 10.8614 22.9588 11.1323 22.6879C11.4032 22.417 11.5553 22.0496 11.5553 21.6666V15.8888C11.5553 15.5057 11.4032 15.1383 11.1323 14.8674C10.8614 14.5965 10.494 14.4443 10.1109 14.4443ZM4.33312 21.6666V15.8888H10.1109V21.6666H4.33312Z"
        fill="white"
      />
      <path
        d="M21.6675 14.4443H15.8898C15.5067 14.4443 15.1393 14.5965 14.8684 14.8674C14.5975 15.1383 14.4453 15.5057 14.4453 15.8888V21.6666C14.4453 22.0496 14.5975 22.417 14.8684 22.6879C15.1393 22.9588 15.5067 23.111 15.8898 23.111H21.6675C22.0506 23.111 22.418 22.9588 22.6889 22.6879C22.9598 22.417 23.112 22.0496 23.112 21.6666V15.8888C23.112 15.5057 22.9598 15.1383 22.6889 14.8674C22.418 14.5965 22.0506 14.4443 21.6675 14.4443ZM15.8898 21.6666V15.8888H21.6675V21.6666H15.8898Z"
        fill="white"
      />
    </svg>
  );
};

export default Block;
