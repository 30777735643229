import React from "react";

interface Props {
  className?: string;
}

const List = (props: Props) => {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.875 5.3125H20.125C20.987 5.3125 21.8136 5.65491 22.4231 6.2644C23.0326 6.8739 23.375 7.70055 23.375 8.5625V13.4375C23.375 14.2995 23.0326 15.1261 22.4231 15.7356C21.8136 16.3451 20.987 16.6875 20.125 16.6875H3.875C3.01305 16.6875 2.1864 16.3451 1.5769 15.7356C0.96741 15.1261 0.625 14.2995 0.625 13.4375V8.5625C0.625 7.70055 0.96741 6.8739 1.5769 6.2644C2.1864 5.65491 3.01305 5.3125 3.875 5.3125ZM3.875 6.9375C3.44402 6.9375 3.0307 7.10871 2.72595 7.41345C2.4212 7.7182 2.25 8.13152 2.25 8.5625V13.4375C2.25 13.8685 2.4212 14.2818 2.72595 14.5865C3.0307 14.8913 3.44402 15.0625 3.875 15.0625H20.125C20.556 15.0625 20.9693 14.8913 21.274 14.5865C21.5788 14.2818 21.75 13.8685 21.75 13.4375V8.5625C21.75 8.13152 21.5788 7.7182 21.274 7.41345C20.9693 7.10871 20.556 6.9375 20.125 6.9375H3.875ZM0.625 1.25C0.625 1.03451 0.710602 0.827849 0.862976 0.675476C1.01535 0.523102 1.22201 0.4375 1.4375 0.4375H22.5625C22.778 0.4375 22.9847 0.523102 23.137 0.675476C23.2894 0.827849 23.375 1.03451 23.375 1.25C23.375 1.46549 23.2894 1.67215 23.137 1.82452C22.9847 1.9769 22.778 2.0625 22.5625 2.0625H1.4375C1.22201 2.0625 1.01535 1.9769 0.862976 1.82452C0.710602 1.67215 0.625 1.46549 0.625 1.25ZM0.625 20.75C0.625 20.5345 0.710602 20.3278 0.862976 20.1755C1.01535 20.0231 1.22201 19.9375 1.4375 19.9375H22.5625C22.778 19.9375 22.9847 20.0231 23.137 20.1755C23.2894 20.3278 23.375 20.5345 23.375 20.75C23.375 20.9655 23.2894 21.1722 23.137 21.3245C22.9847 21.4769 22.778 21.5625 22.5625 21.5625H1.4375C1.22201 21.5625 1.01535 21.4769 0.862976 21.3245C0.710602 21.1722 0.625 20.9655 0.625 20.75Z"
        fill="white"
      />
    </svg>
  );
};

export default List;
